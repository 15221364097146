.notifications_wrapper {
	background-color: #fff;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: column;
	padding: 30px 10px;
	border-radius: 20px;
	box-shadow: 0px 4px 4px rgba(23, 43, 133, 0.15);
	color: #4285f4;
	width: 100%;
	max-height: 600px;
	overflow-y: scroll;
	overflow-x: hidden;
}

.notifications_wrapper::-webkit-scrollbar-track {
	background-color: #ffff;
}

.notifications_wrapper::-webkit-scrollbar {
	width: 9px;
	background-color: #ffff;
}

.notifications_wrapper::-webkit-scrollbar-thumb {
	background-color: #57585ad2;
	border-radius: 8px;
}

.notifications_container {
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 55px;
	right: -20px;
	height: 500px;
	width: 400px;
	background-color: #ffff;
	border-radius: 2rem;
	box-shadow: 0 4px 7px rgba(0, 0, 0, 0.2);
}
.notifications_header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 24px;
	padding: 15px;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
}

.notifications_body {
	height: 400px;
	overflow: auto;
}
.notifications_item {
	position: relative;
	cursor: pointer;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #6d9eee3a;
	border: 1px #6d9fee solid;
	border-radius: 25px;
	width: 90%;
	transition: all 1s;
}

.notifications_item_viewed {
	position: relative;
	cursor: pointer;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border: 1px #6d9fee solid;
	border-radius: 25px;
	position: relative;
	margin-bottom: 1rem;
	width: 90%;
	transition: all 1s;
}

.notifications_item:hover,
.notifications_item_viewed:hover {
	transition: all 2s;
	background-color: #4285f4;
}

.notifications_cta_wrapper {
	display: none;
}

.notifications_item:hover .notifications_item_icon_wrapper,
.notifications_item:hover .notifications_item_body_wrapper,
.notifications_item:hover .notifications_item_viewed_text,
.notifications_item_viewed:hover .notifications_item_icon_wrapper,
.notifications_item_viewed:hover .notifications_item_body_wrapper,
.notifications_item_viewed:hover .notifications_item_viewed_text {
	visibility: hidden;
}

.notifications_item:hover .notifications_cta_wrapper,
.notifications_item_viewed:hover .notifications_cta_wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	left: 30px;
}

.notifications_item_icon_wrapper {
	width: 15%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.notifications_item_icon_img {
	height: 25px;
	width: 25px;
}

.notifications_item_body_wrapper {
	width: 85%;
	padding: 15px;
}

.notifications_item_body_text {
	font-size: 1rem;
	color: #4285f4;
	max-width: 70%;
}
.notifications_item_body_header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 5px;
}

.notifications_item_body_title {
	font-size: 1rem;
	color: black;
	margin-bottom: 1rem;
}

.notifications_item_body_date {
	font-size: 1rem;
	color: #6d9eeed2;
	margin-right: 1rem;
}

.notifications_body::-webkit-scrollbar-track {
	background-color: #ffff;
}

.notifications_body::-webkit-scrollbar {
	width: 9px;
	background-color: #ffff;
}

.notifications_body::-webkit-scrollbar-thumb {
	background-color: #57585ad2;
	border-radius: 8px;
}

.notifications_item_viewed_text {
	font-size: 0.9rem;
	color: #57585ad2;
	position: absolute;
	right: 2rem;
	bottom: 10px;
}

.notifications_empty_wrapper {
	height: 200px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.notifications_empty_text {
	font-size: 1rem;
}

.loading_container {
	width: 100%;
	min-height: 300px;
	height: 40vh;
	display: flex;
	justify-content: center;
	align-items: center;
}

.button_white_no_border {
	border: none !important;
}

.notifications_body_empty {
	height: 400px;
	overflow: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 2rem;
}

.notifications_icon_box {
	width: 100px;
	height: 100px;
}
