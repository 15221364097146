.factura_container {
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 55px;
	right: -20px;
	height: 500px;
	width: 400px;
	background-color: #ffff;
	border-radius: 2rem;
	box-shadow: 0 4px 7px rgba(0, 0, 0, 0.2);
}
.factura_header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 24px;
	padding: 15px;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
}

.factura_body {
	height: 400px;
	overflow: auto;
}
.factura_item {
	cursor: pointer;
	display: flex;
	justify-content: space-between;
	align-items: center;
	min-height: 80px;
	background-color: rgba(93, 92, 92, 0.1);
	border-radius: 25px;
	padding-left: 2rem;
	width: 100%;
}

.factura_item:hover {
	background-color: #4285f4;
	color: white;
}

.factura_item:hover .factura_item_body_text,
.factura_item:hover .factura_item_body_date,
.factura_item:hover .factura_item_body_title {
	color: white;
}

.factura_item_icon_wrapper {
	justify-content: center;
	align-items: center;
	margin-right: 2rem;
	gap: 1rem;
	display: none;
	transition: all 0.3s;
}

.factura_item:hover .factura_item_icon_wrapper {
	display: flex;
}
.factura_item:hover .factura_item_text_wrapper_last {
	display: none;
}

.factura_item_status_wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
}

.factura_item_icon_img {
	height: 25px;
	width: 25px;
}

.detail_element_seemore:hover {
	scale: 1.2;
}

.factura_item_body_paid_button {
	color: #4285f4;
	border-radius: 10px;
	background-color: #fff;
	padding: 8px 20px;
}

.factura_item_body_wrapper {
	width: 100%;
	display: flex;
	gap: 1rem;
	position: relative;
	margin-left: 1rem;
	margin-right: 2rem;
	justify-content: space-between;
}

.factura_item_text_wrapper {
	display: flex;
	margin-left: 1rem;
	gap: 2rem;
}

.factura_item_body_text {
	font-size: 1rem;
	color: #57585ad2;
	display: flex;
	align-items: center;
}

.factura_item_body_header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 5px;
}

.factura_item_body_title {
	font-size: 1rem;
	color: #4285f4;
	display: flex;
	align-items: center;
	justify-content: center;
}

.factura_item_body_date {
	font-size: 0.9rem;
	color: #6d9eeed2;
	display: flex;
	align-items: center;
}

.factura_body::-webkit-scrollbar-track {
	background-color: #ffff;
}

.factura_body::-webkit-scrollbar {
	width: 9px;
	background-color: #ffff;
}

.factura_body::-webkit-scrollbar-thumb {
	background-color: #57585ad2;
	border-radius: 8px;
}

.factura_item_viewed_text {
	font-size: 0.9rem;
	color: #57585ad2;
	position: absolute;
	right: 2rem;
	bottom: 10px;
}

.factura_empty_wrapper {
	height: 200px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.factura_empty_text {
	font-size: 1rem;
}

.error_Notification {
	text-align: center;
	color: rgb(219, 99, 99);
	position: absolute;
	padding: 10px 20px;
	border: rgb(219, 99, 99) solid 2px;
	border-radius: 12px;
}

.success_Notification {
	text-align: center;
	color: #4285f4;
	position: absolute;
	padding: 10px 20px;
	border: #4285f4 solid 2px;
	border-radius: 12px;
}

.detail_status {
	border: 1px solid #4285f4;
	padding: 8px 15px;
	border-radius: 12px;
	width: 200px;
	text-align: center;
	color: #4285f4;
}

.factura_item:hover .detail_status {
	border: 1px solid #fff;
	color: #fff;
}

@media screen and (max-width: 1635px) {
	.showOnLargeScreen {
		display: none;
	}
}
