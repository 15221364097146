.insignia_footer {
	display: flex;
	gap: 20px;
}

.insignia_footer_img:hover {
	transform: scale(1.05);
	cursor: pointer;
}

.insignia_footer_icon:hover {
	transform: scale(1.05);
	cursor: pointer;
}
