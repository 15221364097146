.container_loader {
	height: 100%;
	width: 100%;
	justify-content: center;
	align-items: center;
	display: flex;
}

.loader {
	border: 6px solid #f3f3f3;
	border-top: 6px solid #4285f4;
	border-radius: 50%;
	width: 40px;
	height: 40px;
	animation: spin 1s linear infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
