.notifications_container {
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 55px;
	right: -20px;
	height: 500px;
	width: 400px;
	background-color: #ffff;
	border-radius: 2rem;
	box-shadow: 0 4px 7px rgba(0, 0, 0, 0.2);
}
.notifications_header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 24px;
	padding: 15px;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
}

.notifications_body {
	height: 400px;
	overflow: auto;
}
.notifications_item {
	cursor: pointer;
	display: flex;
	justify-content: space-between;
	align-items: center;
	min-height: 100px;
	background-color: #6d9eee3a;
	border: 1px #6d9fee solid;
	border-radius: 25px;
}

.notifications_item_viewed {
	cursor: pointer;
	display: flex;
	justify-content: space-between;
	align-items: center;
	min-height: 100px;
	border: 1px #6d9fee solid;
	border-radius: 25px;
	position: relative;
	margin-bottom: 1rem;
}

.notifications_item:hover {
	opacity: 0.7;
}

.notifications_item_icon_wrapper {
	width: 15%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.notifications_item_icon_img {
	height: 25px;
	width: 25px;
}

.notifications_item_body_wrapper {
	width: 85%;
	padding: 15px;
}

.notifications_item_body_text {
	font-size: 1rem;
	color: #4285f4;
	max-width: 70%;
}
.notifications_item_body_header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 5px;
}

.notifications_item_body_title {
	font-size: 1rem;
}

.notifications_item_body_date {
	font-size: 1rem;
	color: #6d9eeed2;
	margin-right: 1rem;
}

.notifications_body::-webkit-scrollbar-track {
	background-color: #ffff;
}

.notifications_body::-webkit-scrollbar {
	width: 9px;
	background-color: #ffff;
}

.notifications_body::-webkit-scrollbar-thumb {
	background-color: #57585ad2;
	border-radius: 8px;
}

.notifications_item_viewed_text {
	font-size: 0.9rem;
	color: #57585ad2;
	position: absolute;
	right: 2rem;
	bottom: 10px;
}

.notifications_empty_wrapper {
	height: 200px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.notifications_empty_text {
	font-size: 1rem;
}

.error_Notification {
	text-align: center;
	color: rgb(219, 99, 99);
	position: absolute;
	padding: 10px 20px;
	border: rgb(219, 99, 99) solid 2px;
	border-radius: 12px;
}

.success_Notification {
	text-align: center;
	color: #4285f4;
	position: absolute;
	padding: 10px 20px;
	border: #4285f4 solid 2px;
	border-radius: 12px;
}

.users_container {
	min-height: 400px;
}

.users_menu {
	margin-top: 20px;
	margin-bottom: 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-radius: 8px;
	min-width: 850px;
}
.users_title_container {
	width: 100%;
	display: flex;
	gap: 20px;
	align-items: center;
}

.users_title_button {
	margin-top: 5px;
	margin-left: 20px;
	padding: 5px 15px;
	width: fit-content;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 7px;
	cursor: pointer;
	transition: all 0.3s;
	border: 1px solid #4285f4;
	border-radius: 15px;
	color: #4285f4;
}

.users_title_button.active {
	background-color: #4285f4;
	color: #fff;
}

.users_title_button.active .users_title_button_text {
	color: #fff;
}

.users_title_button_text {
	color: #4285f4;
	font-weight: bold;
	font-size: 0.9rem;
}

.users_title_button:hover {
	transform: scale(1.02);
	opacity: 0.8;
}

.category {
	display: flex;
	align-items: center;
	gap: 10px;
	justify-content: center;
	min-width: 130px;
	cursor: pointer;
	background-color: rgba(239, 239, 239, 0.3);
	padding: 15px;
	border-radius: 20px;
}

.category:hover {
	background-color: rgba(93, 92, 92, 0.3);
}

.selectedCategory {
	font-weight: bold;
	background-color: rgba(93, 92, 92, 0.3);
}
.plan_item {
	cursor: pointer;
	display: flex;
	justify-content: space-between;
	align-items: center;
	min-height: 150px;
	border: 1px #6d9fee solid;
	border-radius: 25px;
	position: relative;
}

.plan_item_icon_img {
	height: 25px;
	width: 25px;
}

.plan_item_icon_wrapper {
	width: 15%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.plan_item_body_wrapper {
	width: 85%;
	padding: 15px;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: flex-start;
}

.plan_item_body_text {
	font-size: 1rem;
	color: #4285f4;
}

.plan_item_body_header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.plan_item_body_title {
	font-size: 1rem;
}

.plan_item_body_date {
	font-size: 1rem;
	color: #6d9eeed2;
}

.plan_item_body_pay {
	font-size: 0.9rem;
	color: #6d9eeed2;
}

.plan_item_body_section {
	margin-top: 0.8rem;
}

.loading_container {
	width: 100%;
	min-height: 300px;
	height: 40vh;
	display: flex;
	justify-content: center;
	align-items: center;
}

.autocomplete_container {
	width: 100% !important;
}

.users_input_box > div {
	padding: 0px 15px !important;
	border: 1px solid #57585ad2 !important;
	border-radius: 20px !important;
}

.users_input_box > div > fieldset {
	border: none !important;
}

.users_input_box > input,
.users_input_box > div > input {
	color: #57585ad2 !important;
	font-size: 1rem !important;
	padding: 10px;
}

.users_input_box > label[data-shrink='true'] {
	display: none;
}

.users_input_box > label[data-shrink='false'] {
	top: -5px;
}

.users_input_box > label,
.users_input_box > div > input {
	font-size: 1rem !important;
	color: #57585ad2 !important;
	font-family: 'Raleway', sans-serif !important;
}

.users_subtitle {
	font-size: 0.9rem;
	font-weight: bold;
	color: #57585ad2;
	margin-bottom: 1rem;
}

.users_generals_container {
	display: flex;
	align-items: center;
	justify-content: space-around;
	background-color: rgba(239, 239, 239, 0.3);
	padding: 40px 15px;
	border-radius: 25px;
	margin-top: 1rem;
	height: 30vh;
}

.users_generals_wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 2rem;
	cursor: pointer;
}

.users_generals_subtitle {
	font-size: 1rem;
	font-weight: bold;
	color: #4285f4;
}

.users_generals_count {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 0.9rem;
	font-weight: bold;
	color: #4285f4;
	border: 1px solid #4285f4;
	border-radius: 50%;
	height: 70px;
	width: 70px;
	transform: all 0.3s;
}

.users_generals_wrapper:hover .users_generals_subtitle,
.users_generals_wrapper:hover .users_generals_count {
	transform: scale(1.1);
	color: #57585ad2;
}

.users_generals_wrapper:hover .users_generals_count {
	transform: scale(1.1);
	color: #57585ad2;
	border: 2px solid #57585ad2;
}

.users_timefrequency_wrapper {
	gap: 50px;
	display: flex;
	align-items: center;
}

.users_timefrequency_text {
	display: flex;
	align-items: center;
	color: #57585ad2;
	gap: 15px;
	padding: 10px 25px;
	border-radius: 20px;
	border: 1px solid #57585ad2;
}

.users_status {
	background-color: #98cf22e0;
	height: 12px;
	width: 12px;
	border-radius: 50%;
}

.users_status.users_pending {
	background-color: #db6363;
}
