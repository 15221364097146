.bell {
	cursor: pointer;
	width: 30px;
}

.bell:hover {
	opacity: 0.5;
	scale: 0.95;
}

.logoutIcon {
	cursor: pointer;
	width: 27px;
}

.logoutIcon:hover {
	opacity: 0.5;
	scale: 0.95;
}

/* Define the keyframes for the shivering animation */
@keyframes shiver {
	0% {
		transform: translateX(0);
	}
	25% {
		transform: translateX(-5px);
	}
	50% {
		transform: translateX(5px);
	}
	75% {
		transform: translateX(-5px);
	}
	100% {
		transform: translateX(0);
	}
}

/* Apply the shivering animation on hover */
.shivering_element {
	transition: transform 0.1s ease-in-out;
}

.shivering_element:hover {
	animation: shiver 0.3s infinite;
}
.blue_dot_wrapper {
	z-index: 10;
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	top: -13px;
	right: 55px;
	color: #ffff;
	padding: 5px;
	min-width: 29px;
	background-color: #6d9fee;
	border-radius: 50%;
}

.blue_dot_number {
	z-index: 1001;
	font-size: 12px;
}

.QR_modal {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 55px;
	right: -20px;
	height: 430px;
	width: 300px;
	padding: 15px;
	background-color: #ffff;
	border-radius: 2rem;
	box-shadow: 0 4px 7px rgba(0, 0, 0, 0.2);
}

.QR_container {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
}
.QR_image {
	width: 80%;
}

.GooglePlay_image {
	width: 75%;
}

.GooglePlay_image img:hover,
.AppStore_image img:hover {
	transform: scale(1.05);
}

.AppStore_image {
	width: 95%;
}

.AppStore_image img {
	width: 100%;
}

.AppStore_disclaimer {
	opacity: 0.7;
	margin-top: 0.5rem;
}

.nav_admin_wrapper {
	display: none; /* Hidden by default for large screens */
	display: flex; /* Flex display */
	position: fixed; /* Fixed position */
	top: 0; /* Positioned at the top */
	width: 100%; /* Full width */
	height: 100vh; /* Full height of the viewport */
	opacity: 1; /* Fully opaque */
	transition-duration: 300ms; /* Transition duration */
	transition-property: all; /* Apply transition to all properties */
	background-color: #4285f4; /* Background color */
	z-index: 1001;
}

@media (min-width: 992px) {
	.nav_admin_wrapper {
		display: none; /* Hidden for large screens */
	}
}
