.notification_container {
	position: relative;
	width: 100%;
	background-color: #4285f4;
	color: white;
	padding: 15px;
	border-radius: 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	z-index: 1;
	margin-top: 20px;
}

.notification_container_title {
	color: white;
	font-size: 1.3rem;
	font-weight: bold;
}

.notification_container_container {
	display: flex;
	align-items: center;
	justify-content: space-around;
	padding: 30px;
}

.notification_container_message_wrapper {
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.close_button {
	background: none;
	border: none;
	color: white;
	font-size: 50px;
	cursor: pointer;
	transition: all 0.3s ease-in;
}

.close_button:hover {
	transform: scale(1.1);
}
