.facturas_messageContainer {
	width: 100%;
	height: 400px;
	justify-content: center;
	align-items: center;
	display: flex;
}

.loading_container {
	width: 100%;
	min-height: 300px;
	height: 40vh;
	display: flex;
	justify-content: center;
	align-items: center;
}

.details_container {
	width: calc(100% + 8px);
	min-width: 850px;
	height: 40vh;
	overflow-y: auto;
}

.details_container::-webkit-scrollbar-track {
	background-color: #ffff;
}

.details_container::-webkit-scrollbar {
	width: 9px;
	background-color: #ffff;
}

.details_container::-webkit-scrollbar-thumb {
	background-color: #57585ad2;
	border-radius: 8px;
}
