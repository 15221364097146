.generals_container {
	display: flex;
	align-items: center;
	justify-content: space-around;
	background-color: rgba(239, 239, 239, 0.3);
	padding: 40px 15px;
	border-radius: 25px;
	margin-top: 1rem;
	margin-bottom: 2rem;
	min-height: 170px;
	min-width: 750px;
}

.generals_container_buttons {
	display: flex;
	align-items: center;
	justify-content: space-around;
	width: 100%;
}

.generals_wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1rem;
	cursor: pointer;
}

.generals_subtitle {
	font-size: 1rem;
	font-weight: bold;
	color: #57585ad2;
}

.generals_count {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 0.9rem;
	color: #57585ad2;
	border: 1px solid #57585ad2;
	border-radius: 50%;
	height: 70px;
	width: 70px;
	transform: all 0.3s;
}

.generals_wrapper_active > .generals_subtitle {
	font-weight: bold;
	color: #4285f4;
}
.generals_wrapper_active > .generals_count {
	font-weight: bold;
	color: #4285f4;
	border: 2px solid #4285f4;
}

.generals_wrapper:hover .generals_count {
	transform: scale(1.1);
	color: #57585ad2;
}

.generals_wrapper:hover .generals_count {
	transform: scale(1.1);
	color: #57585ad2;
	border: 2px solid #57585ad2;
}

.timefrequency_wrapper {
	gap: 50px;
	display: flex;
	align-items: center;
}

.timefrequency_text {
	display: flex;
	align-items: center;
	color: #57585ad2;
	gap: 15px;
	padding: 10px 25px;
	border-radius: 20px;
	border: 1px solid #57585ad2;
}

.status {
	background-color: #98cf22e0;
	height: 12px;
	width: 12px;
	border-radius: 50%;
}

.status.pending {
	background-color: #db6363;
}
