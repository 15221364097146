.presentar_modalContainer {
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;
}

.presentar_modal {
	min-height: 200px;
	width: 550px;
	padding: 15px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #ffff;
	border-radius: 2rem;
	box-shadow: 0 4px 7px rgba(0, 0, 0, 0.2);
}

.presentar_loadingMessage {
	color: #4285f4;
	font-size: 1.5rem;
}

.presentar_warningMessage {
	color: rgb(219, 99, 99);
	font-size: 1.5rem;
}

.presentar_loadingContainer {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 2rem;
}

.container_loader {
	height: 100%;
	width: 100%;
	justify-content: center;
	align-items: center;
	display: flex;
}

.loader {
	border: 3px solid #f3f3f3;
	border-top: 3px solid #4285f4;
	border-radius: 50%;
	width: 50px;
	height: 50px;
	animation: spin 1s linear infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
