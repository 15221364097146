.welcome_wrapper {
	width: 100%;
	background-color: #4285f4;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	flex-direction: column;
	padding: 20px 40px;
	border-radius: 20px;
	box-shadow: 0px 4px 4px rgba(23, 43, 133, 0.15);
	color: #fff;
}

.main_grid {
	margin-top: 20px;
	width: 100%;
	display: flex;
	gap: 20px;
	justify-content: space-between;
}

.main_wrapper {
	background-color: #fff;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: column;
	padding: 30px 10px;
	border-radius: 20px;
	box-shadow: 0px 4px 4px rgba(23, 43, 133, 0.15);
	color: #4285f4;
	min-width: 370px;
	width: calc(50% - 20px);
}

.main_title {
	font-weight: bold;
	font-size: 2rem;
	padding: 45px 35px 0 45px;
}

.main_subtitle {
	font-weight: 600;
	font-size: 1.2rem;
	color: black;
}

.main_card_title {
	font-weight: bold;
	font-size: 1.6rem;
}

.main_card_subtitle {
	font-size: 1rem;
	color: black;
	max-width: 290px;
	padding-bottom: 30px;
}

.main_card_wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 2rem;
	text-align: center;
}

.main_card_img_wrapper {
	margin-top: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #4285f4;
	padding: 35px;
	border-radius: 50%;
	transition: all 0.5s;
}

.main_card_img_wrapper:hover {
	scale: 1.1;
}

.main_card_img {
	width: 50px;
}

.info_redCircle {
	display: flex;
	height: 15px;
	width: 15px;
	border-radius: 50%;
	background-color: rgb(219, 99, 99);
}

.info_profileComplete {
	background-color: #4285f4;
	color: #fff;
	padding: 2px 7px;
	border-radius: 8px;
	font-size: 0.8rem;
	font-weight: 300;
}

.info_profileIncomplete {
	background-color: rgb(219, 99, 99);
	color: #fff;
	padding: 2px 7px;
	border-radius: 8px;
	font-size: 0.8rem;
	font-weight: 300;
	margin-left: 0.5rem;
}

.profile_option {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 10px;
}

.profile_option:hover {
	animation: moveRight 0.5s;
}

.info_link {
	cursor: pointer;
	color: rgb(219, 99, 99);
	text-decoration: underline;
	text-decoration-color: rgb(219, 99, 99);
}

.insignia_footer_img:hover {
	transform: scale(1.05);
	cursor: pointer;
}

.insignia_footer {
	display: flex;
	flex-direction: column;
	gap: 20px;
	width: 150px;
	margin-top: 20px;
	margin-bottom: 20px;
}

.insignia_footer_img_apple {
	height: 48px;
	width: 150px;
}

.main_card_wrapper_profile {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 2rem;
	text-align: left;
	width: 60%;
	padding: 20px;
}

.main_card_img_wrapper_profile {
	margin-top: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 35px;
	border-radius: 50%;
	width: 40%;
}

.main_wrapper_profile {
	background-color: #fff;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 30px 10px;
	border-radius: 20px;
	box-shadow: 0px 4px 4px rgba(23, 43, 133, 0.15);
	color: #4285f4;
	width: 100%;
}

.main_wrapper_profile .main_card_wrapper {
	color: black;
	font-size: 1.1rem;
}

.main_title_profile {
	display: flex;
	align-items: center;
	gap: 1rem;
}

.main_number_wrapper {
	background-color: #4285f4;
	border-radius: 50%;
	color: #fff;
	width: 45px;
	height: 45px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 1.5rem;
}

.main_card_wrapper_profile .main_card_subtitle {
	color: black;
	font-weight: bold;
	font-size: 1.6rem;
	margin: 0;
	padding: 0;
}

.main_card_wrapper_profile .main_card_text {
	color: black;
	font-size: 1.1rem;
}

.main_wrapper_profile .main_card_img {
	width: 170px;
}

.main_title_button_wrapper {
	display: flex;
	gap: 30px;
	flex-wrap: wrap;
}

.main_title_button:hover {
	transform: translateX(10px);
	opacity: 0.5;
	transition: all 0.3s ease-in;
}

@media screen and (max-width: 768px) {
	.main_wrapper_profile {
		flex-direction: column;
	}
	.main_card_wrapper_profile {
		align-items: center;
		text-align: center;
	}
	.main_title_button_wrapper {
		justify-content: center;
	}
}

@media (max-width: 467px) {
	.main_number_wrapper {
		display: none;
	}
	.main_card_img_wrapper_profile {
		width: 100%;
	}
	.main_card_wrapper_profile {
		width: 100%;
		padding-top: 0;
	}
	.main_title_button {
		font-size: 30px;
		transform: none;
	}
	.button_white,
	.button_blue {
		font-size: 16px !important;
		min-width: 0 !important;
		max-width: none !important;
		width: 200px !important;
		justify-content: center !important;
	}
}
