.loading_container {
	width: 100%;
	height: 40vh;
	display: flex;
	justify-content: center;
	align-items: center;
}

.hero_button_wrapper {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: flex-start;
}

.hero_button {
	margin-top: 5px;
	margin-left: -244px;
	padding: 15px 40px;
	width: fit-content;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 7px;
	cursor: pointer;
	transition: all 0.3s;
	border: 2px solid #4285f4;
	border-radius: 15px;
}

.hero_button:hover {
	transform: scale(1.02);
	opacity: 0.8;
}

.hero_button_text {
	color: #4285f4;
	font-weight: bold;
	font-size: 1.2rem;
}

.factura_container {
	width: calc(100% + 8px);
	min-width: 750px;
	height: 40vh;
	overflow-y: auto;
}

.factura_container::-webkit-scrollbar-track {
	background-color: #ffff;
}

.factura_container::-webkit-scrollbar {
	width: 9px;
	background-color: #ffff;
}

.factura_container::-webkit-scrollbar-thumb {
	background-color: #57585ad2;
	border-radius: 8px;
}

.factura_subtitle {
	font-size: 1.3rem;
	font-weight: bold;
	color: #4285f4;
	margin-bottom: 1.8rem;
}
