.page_subtitle {
	font-size: 1.3rem;
	font-weight: bold;
	color: rgba(87, 87, 87, 0.55);
	margin-bottom: 0.7rem;
}

.page_sidemenu {
	width: 250px;
}

.page_main_container {
	margin-left: 300px;
	padding-top: 30px;
	padding-bottom: 20px;
}

.page_main_welcome_container {
	min-height: 60vh;
}

.modal_success_text {
	font-size: 1.2rem;
	color: #4285f4;
	font-weight: bold;
	max-width: 400px;
	text-align: center;
}

.modal_success_subtitle {
	font-size: 1rem;
	color: rgba(87, 87, 87, 0.55);
	font-weight: bold;
	max-width: 400px;
	text-align: center;
}

.modal_error_wrapper {
	display: flex;
	justify-content: center;
	position: fixed;
	left: 0;
	bottom: 0;
	transition: all 0.3s ease-in-out;
	z-index: 1000;
	width: 100%;
}

.modal_error {
	display: flex;
	gap: 10px;
	justify-content: center;
	font-size: 1.2rem;
	color: #fff;
	padding: 40px;
	background-color: #db6363;
	width: 100%;
	text-align: center;
}

.modal_error > button:hover {
	transform: scale(1.2);
}

.slide_up {
	opacity: 0;
	transform: translateY(100%);
	transition: opacity 0.5s, transform 0.5s;
}

.slide_up_show {
	opacity: 1;
	transform: translateY(0);
}

@media screen and (max-width: 1280px) {
	.page_sidemenu {
		display: none;
	}
	.page_main_container {
		margin-left: 0;
	}
}

.button_blue,
.button_white,
.button_alternative {
	font-size: 20px;
	font-weight: 500;
	padding: 1rem 1.25rem;
	border-radius: 1rem;
	min-width: 290px;
	max-width: max-content;
	display: flex;
	align-items: center;
	gap: 1rem;
	justify-content: center;
}

.button_blue {
	color: #fff;
	background-color: #4285f4;
	border: 2px solid #4285f4;
}

.button_white {
	color: #4285f4;
	background-color: #fff;
	border: 2px solid #4285f4;
}

.button_alternative {
	color: #fff;
	background-color: inherit;
	border: 2px solid #fff;
}

.button_blue:hover,
.button_white:hover,
.button_alternative:hover {
	transform: scale(1.05);
	opacity: 0.7;
	transition: all 0.5s;
}

.text_input {
	margin-top: 0.6rem !important;
	margin-bottom: 1rem !important;
}

.autocomplete_input fieldset {
	display: none;
}

.autocomplete_input > div > div {
	height: 65px !important;
}

.select_input {
	margin-top: 0.6rem !important;
	margin-bottom: 1rem !important;
	border: 1.7px solid #57585ad2 !important;
	border-radius: 20px !important;
}

.select_input > div {
	color: #57585ad2 !important;
	font-size: 1rem !important;
}

.select_input_error {
	border: 1.7px solid #db6363 !important;
}

.select_input > fieldset {
	border: none !important;
}

.text_input > div {
	padding: 3px 10px;
	border: 1.7px solid #57585ad2;
	border-radius: 20px;
}

.text_input > div > fieldset {
	border: none !important;
}

.text_input > input {
	color: #57585ad2 !important;
	font-size: 1rem !important;
}

.text_input > div > input {
	color: #57585ad2 !important;
	font-size: 1rem !important;
}

.text_input_subtitle {
	font-size: 0.9rem;
	color: #4285f4;
	font-weight: bold;
}

.text_input_error > div {
	border: 1.7px solid #db6363 !important;
}

.filter_subtitle {
	font-size: 0.9rem;
	font-weight: bold;
	color: #57585ad2;
	margin-bottom: 1rem;
}

.filter_input_box > div {
	padding: 0px 15px !important;
	border: 1px solid #57585ad2 !important;
	border-radius: 20px !important;
}

.filter_input_active > div {
	border: 2px solid #57585ad2 !important;
}

.filter_input_box > div > fieldset {
	border: none !important;
}

.filter_input_box > input,
.filter_input_box > div > input {
	color: #57585ad2 !important;
	font-size: 1rem !important;
	padding: 10px;
}

.filter_input_box > label[data-shrink='true'] {
	display: none;
}

.users_input_box > label[data-shrink='false'] {
	top: -5px;
}

.filter_input_box > label,
.filter_input_box > div > input {
	font-size: 1rem !important;
	color: #57585ad2 !important;
	font-family: 'Raleway', sans-serif !important;
}

.hero_button_wrapper {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: flex-start;
}

.hero_button {
	margin-top: 5px;
	padding: 15px 40px;
	width: fit-content;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 7px;
	cursor: pointer;
	transition: all 0.3s;
	border: 2px solid #4285f4;
	border-radius: 15px;
}

.hero_button:hover {
	transform: scale(1.02);
	opacity: 0.8;
}

.hero_button_text {
	color: #4285f4;
	font-weight: bold;
	font-size: 1.2rem;
}
