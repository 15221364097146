.notification_banner {
	position: fixed;
	top: 100px;
	right: 0;
	width: 100%;
	background-color: #4285f4;
	color: white;
	padding: 15px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	z-index: 1000;
}

.notification_banner.notification_error {
	background-color: #db6363;
}

.notification_banner_title {
	color: white;
	font-size: 1.3rem;
	font-weight: bold;
}

.notification_banner_container {
	display: flex;
	align-items: center;
	justify-content: space-around;
	padding: 30px;
}

.notification_banner_message_wrapper {
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.close_button {
	background: none;
	border: none;
	color: white;
	font-size: 50px;
	cursor: pointer;
	transition: all 0.3s ease-in;
}

.close_button:hover {
	transform: scale(1.1);
}
