.button:hover {
	transform: scale(1.05);
	opacity: 0.7;
	transition: all 0.5s;
}

.clickOutsideWrapper {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}
