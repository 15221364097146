.button_ClickUpload {
	background-color: #4285f4;
	padding: 5px 10px;
	border-radius: 8px;
	color: #fff;
	cursor: pointer;
}

.button_ClickUpload:hover {
	transform: scale(1.1);
}

.fileUploader_error {
	display: flex;
	align-items: center;
	justify-content: center;
	color: #db6363;
	font-weight: 600;
	font-size: 1.2rem;
	margin-top: 1.2rem;
	text-align: center;
}

.uploadFile_buttonWrapper {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 15px;
}

.fileBoxWrapper {
	padding: 0;
	margin: 0;
	width: 100%;
	max-width: 700px;
}

.fileNameBoxWrapper {
	width: 100%;
	display: flex;
	justify-content: center;
	gap: 15px;
	align-items: center;
	margin-top: 10px;
	margin-bottom: 10px;
}

.fileNameBox {
	background-color: #acc6f2;
	border-radius: 8px;
	color: white;
	display: flex;
	align-items: center;
	flex-wrap: nowrap;
	gap: 15px;
	padding: 30px 20px;
}

.fileBox {
	width: 100%;
	height: 200px;
	border: #4285f4 solid 2px;
	border-radius: 15px;
	justify-content: center;
	align-items: center;
	display: flex;
}

.fileNameBox > svg {
	cursor: pointer;
}

.fileNameBox > svg:hover {
	transform: scale(1.1);
}

.uploadFile_selectButton {
	background-color: #4285f4;
	color: #fff;
}

.uploadFile_selectButton:hover {
	transform: scale(1.05);
	opacity: 0.7;
	transition: all 0.5s;
}
