@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
.app {
	font-family: 'Raleway', sans-serif;
}

html::-webkit-scrollbar-track {
	background-color: #ffff;
}

html::-webkit-scrollbar {
	width: 11px;
	background-color: #ffff;
}

html::-webkit-scrollbar-thumb {
	background-color: #57585ad2;
	border-radius: 10px;
}

.container {
	max-width: 80% !important;
	margin: 0 auto;
}

@media screen and (max-width: 1440px) {
	.container {
		max-width: 90% !important;
		margin: 0 auto;
	}
}

.proces {
	background: url('./assets/images/Group\ 74.png');
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.emapezar {
	background: url('./assets/images/Group\ 71.png');
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	margin-top: -150px;
}

.footer_ul li {
	padding: 10px 0;
}

.input_box {
	background: #ffffff;
	width: 100%;
	border-radius: 1rem;
	outline: none;
	border: 1px solid #c9c9c9;
	box-shadow: 0 4px 4px rgba(23, 43, 133, 0.15);
}

.input_box_declaration {
	background: #ffffff;
	box-shadow: none;
	width: 100%;
	border-radius: 10px;
	outline: none;
}

.input_box_declaration > div {
	border: 2px solid #c9c9c9;
	background: #ffffff;
	box-shadow: none;
	width: 100%;
	border-radius: 10px;
}

.input_box_declaration > label[data-shrink='true'] {
	top: -12px;
}

.input_box_declaration > div > fieldset {
	border: none;
}

.input_box_users > input {
	color: #c9c9c9;
}

.input_box_users > div {
	border: 1px solid #c9c9c9;
	border-radius: 10px;
	outline: none;
}
.input_box_users > div > fieldset {
	border: none;
}

.input_box_users > label[data-shrink='true'] {
	top: -12px;
}

.input_box_error {
	background: #ffffff;
	border: 1px solid rgb(219, 99, 99);
	box-shadow: 0px 4px 4px rgba(23, 43, 133, 0.15);
	width: 100%;
	border-radius: 10px;
	outline: none;
}

.input_box_RFC {
	background: #ffffff;
	border: 1px solid #c9c9c9;
	box-shadow: 0px 4px 4px rgba(23, 43, 133, 0.15);
	width: 70%;
	border-radius: 10px;
	outline: none;
}

.chakbox {
	-webkit-appearance: none;
	position: relative;
	width: 30px;
	height: 30px;
	background: #ffffff;
	border: 1px solid #c9c9c9;
	box-shadow: 0px 4px 4px rgba(23, 43, 133, 0.15);
	border-radius: 50%;
}

.chakbox_Error {
	-webkit-appearance: none;
	position: relative;
	width: 30px;
	height: 30px;
	background: #ffffff;
	border: 1px solid rgb(219, 99, 99);
	box-shadow: 0px 4px 4px rgba(23, 43, 133, 0.15);
	border-radius: 50%;
}

.chakbox::before {
	content: '';
	position: absolute;
	top: 55%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
	height: 100%;
	pointer-events: none;
	background-image: url('./assets/images/chake.png');
	background-size: contain;
	background-repeat: no-repeat;
	display: none;
}

.chakbox:checked::before {
	display: block;
}

.input_data {
	max-width: 100%;
	position: relative;
	height: 40px;
}

.input_data input {
	height: 100%;
	width: 100%;
	border: none;
	outline: none;
	border-bottom: 2px solid #979797;
}

.input_data input:focus ~ label {
	transform: translateY(-20px);
}
.input_data label {
	position: absolute;
	left: 0;
	bottom: 10px;
	color: #979797;
	pointer-events: none;
	transition: all 0.3s ease;
}

.price_box {
	border: 1px solid #d8d8d8;
	box-shadow: 0px 4px 10px rgba(23, 43, 133, 0.15);
	border-radius: 10px;
}

.prement_box {
	box-shadow: 0px 4px 10px rgba(23, 43, 133, 0.2);
	border-radius: 10px;
}

.message {
	display: flex;
	align-items: center;
	gap: 10px;
}

.message div span {
	background: #4285f4;
	color: white;
	padding: 0.7rem;
	border-radius: 0.5rem;
	max-width: 28rem;
	width: fit-content;
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	position: relative;
	z-index: 2;
}

.message div span::before {
	content: '';
	width: 10px;
	height: 10px;
	background: #4285f4;
	position: absolute;
	left: -5px;
	top: 45%;
	transform: rotate(45deg);
	z-index: 1;
}

.message > span:nth-child(2) {
	color: var(--textColor);
	align-self: end;
}

.own {
	align-self: flex-end;
	border-radius: 1rem 1rem 0 1rem;
	flex-direction: row-reverse;
}

.own div span {
	background: #cad8ef;
	border-radius: 0.5rem;
	color: #000;
	position: relative;
}

.own div span::before {
	content: '';
	width: 10px;
	height: 10px;
	background: #cad8ef;
	position: absolute;
	left: 98%;
	top: 45%;
	transform: rotate(45deg);
	z-index: 1;
}

.wrapper {
	width: 100%;
}

#sdroot {
	width: 100%;
}
.css-fa0n69 {
	position: fixed !important;
	left: 20px;
	bottom: 20px;
}
.css-1jd96c2 .MuiSpeedDial-actions {
	position: fixed;
	left: 20px;
	bottom: 57px;
}

.rocketchat-widget {
	left: 0 !important;
}

.form-error {
	font-size: 1rem;
	color: rgb(219, 99, 99);
	margin-left: 1.2rem;
	margin-top: 0.3rem;
	position: absolute;
}

.button-login:hover {
	color: #acc6f2;
}

.button-signup:hover {
	color: #acc6f2;
}

.fileBoxWrapper {
	padding: 0;
	margin: 0;
	width: 100%;
}

.fileBox {
	width: 100%;
	height: 100px;
	border: #4285f4 solid 2px;
	border-radius: 15px;
	justify-content: center;
	align-items: center;
	display: flex;
}

.administrador {
	display: flex;
	gap: 50px;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.administradorSubtitle {
	margin-left: 8px;
	font-size: 20px;
}

.login-container {
	min-height: calc(100vh - 180px);
}

.MuiPaper-root {
	border-radius: 20px !important;
}

.MuiDataGrid-virtualScroller::-webkit-scrollbar-track,
.MuiPaper-root::-webkit-scrollbar-track {
	background-color: #ffff !important;
}

.MuiDataGrid-virtualScroller::-webkit-scrollbar {
	width: 11px !important;
	background-color: #ffff !important;
}

.MuiPaper-root::-webkit-scrollbar {
	width: 11px !important;
	background-color: #57585ad2 !important;
}

.MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb,
.MuiPaper-root::-webkit-scrollbar-thumb {
	background-color: #57585ad2 !important;
	border-radius: 10px !important;
}

.MuiTypography-root {
	font-size: 1rem !important;
	color: #57585ad2;
}

.MuiFormLabel-root[data-shrink='true'] {
	display: none;
}
