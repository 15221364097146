.detalle_userError {
	display: flex;
	width: 100%;
	max-width: 900px;
	position: absolute;
	justify-content: center;
	align-items: center;
	top: -45px;
	color: rgb(219, 99, 99);
}

.detalle_errorContainer {
	width: 100%;
	height: 400px;
	justify-content: center;
	align-items: center;
	display: flex;
}

.detalle_modalContainer {
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;
}

.detalle_modal {
	min-height: 200px;
	width: 550px;
	padding: 15px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #ffff;
	border-radius: 2rem;
	box-shadow: 0 4px 7px rgba(0, 0, 0, 0.2);
}

.detalle_loadingMessage {
	color: #4285f4;
	font-size: 1.5rem;
}

.detalle_loadingContainer {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 2rem;
}

.container_loader {
	height: 100%;
	width: 100%;
	justify-content: center;
	align-items: center;
	display: flex;
}

.loader {
	border: 3px solid #f3f3f3;
	border-top: 3px solid #4285f4;
	border-radius: 50%;
	width: 50px;
	height: 50px;
	animation: spin 1s linear infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.filters_input_box {
	width: 100%;
}

.filters_input_box > div {
	padding: 3px 10px !important;
	border: 1px solid #57585ad2 !important;
	border-radius: 20px !important;
}

.filters_input_box > div > fieldset {
	border: none !important;
}

.filters_input_box > input {
	color: #57585ad2 !important;
	font-size: 1rem !important;
}

.filters_input_box > div > input {
	color: #57585ad2 !important;
	font-size: 1rem !important;
}

.filters_input_box > label[data-shrink='true'] {
	display: none;
}

.filters_input_box > label,
.filters_input_box > div > input {
	font-size: 1rem !important;
	color: #57585ad2 !important;
	font-family: 'Raleway', sans-serif !important;
	top: -4px;
}

.filters_subtitle {
	font-size: 0.9rem;
	font-weight: bold;
	color: #4285f4;
	margin-bottom: 5px;
	margin-left: 10px;
}

.columns_wrapper {
	max-width: 1000px;
	display: flex;
	align-items: flex-start;
	flex-direction: row;
	width: 100%;
	gap: 20px;
}

.columns_input_wrapper {
	padding-top: 15px;
	padding-bottom: 15px;
}

.columns_input_element {
	width: 100%;
	max-width: 1000px;
}

.fileuploader_wrapper {
	margin-top: 60px;
	margin-bottom: 60px;
	max-width: 1000px;
}

@media (max-width: 900px) {
	.columns_wrapper {
		flex-direction: column;
	}
	.columns_input_wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 0;
	}
}
