.notifications_container {
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 55px;
	right: -20px;
	height: 500px;
	width: 500px;
	background-color: #ffff;
	border-radius: 2rem;
	box-shadow: 0 4px 7px rgba(0, 0, 0, 0.2);
}
.notifications_header {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 24px;
	padding: 15px;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
}

.notifications_body {
	height: 400px;
	overflow: auto;
}
.notifications_item {
	cursor: pointer;
	display: flex;
	justify-content: space-between;
	align-items: center;
	min-height: 85px;
	background-color: #6d9eee3a;
	border: 1px #6d9fee solid;
	border-radius: 8px;
}

.notifications_item_viewed {
	cursor: pointer;
	display: flex;
	justify-content: space-between;
	align-items: center;
	min-height: 85px;
	border: 1px #6d9fee solid;
	border-radius: 8px;
	position: relative;
}

.notifications_item:hover {
	opacity: 0.7;
}

.notifications_item_icon_wrapper {
	width: 15%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.notifications_item_icon_img {
	height: 20px;
	width: 20px;
}

.notifications_item_body_wrapper {
	width: 85%;
	padding: 15px;
}

.notifications_item_body_text {
	font-size: 14px;
	color: #4285f4;
}
.notifications_item_body_header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 5px;
}

.notifications_item_body_title {
	font-size: 16px;
}

.notifications_item_body_date {
	font-size: 12px;
	color: #6d9eeed2;
}

.notifications_body::-webkit-scrollbar-track {
	background-color: #ffff;
}

.notifications_body::-webkit-scrollbar {
	width: 9px;
	background-color: #ffff;
}

.notifications_body::-webkit-scrollbar-thumb {
	background-color: #57585ad2;
	border-radius: 8px;
}

.notifications_item_viewed_text {
	font-size: 10px;
	color: #57585ad2;
	position: absolute;
	right: 15px;
	bottom: 5px;
}

.notifications_body_empty {
	height: 400px;
	overflow: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 2rem;
}

.notifications_icon_box {
	width: 100px;
	height: 100px;
}

.button_white_no_border {
	border: none !important;
}
