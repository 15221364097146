.plan__HomePriceContainer {
	max-width: 1240px;
	margin: 0 auto;
	padding: 0 20px;
}

.plan__MainContainer {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	width: 100%;
}

.plan__ManageAccount {
	background-color: #4285f4;
	color: #ffff;
	font-weight: 500;
	font-size: 16px;
	border-radius: 10px;
	padding: 10px 15px;
}

.plan__Container_Year {
	font-size: 2.2rem;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	background-color: #ffff;
	color: #000;
	padding: 25px;
	padding-top: 45px;
	padding-bottom: 45px;
	position: relative;
	text-align: center;
	align-items: stretch;
	transition: all 0.3s;
	z-index: 1;
	min-height: 450px;
}

.plan__SelectPlan__button {
	background-color: #4285f4;
	color: #ffff;
	font-weight: 500;
	font-size: 20px;
	border-radius: 10px;
	padding: 10px 15px;
	width: 100%;
	transition: all ease-in-out 0.3s;
}

.plan__ManageAccount:hover,
.plan__SelectPlan__button:hover {
	transform: scale(1.05);
	opacity: 0.9;
}

.plan__Container {
	font-size: 2.2rem;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	background-color: #ffff;
	color: #000;
	padding: 25px;
	padding-top: 45px;
	padding-bottom: 45px;
	position: relative;
	align-items: stretch;
	text-align: center;
	transition: all 0.3s;
	z-index: 1;
	min-height: 450px;
}

.plan__Title {
	font-size: 2.2rem;
	font-weight: 600;
}

.plan__Body {
	font-size: 1rem;
	padding: 0px 16px 25px 16px;
	text-align: center;
}

.plan__PriceWrapper {
	margin-top: 15px;
	margin-bottom: 15px;
}

.plan__ContainerDescription {
	font-size: 0.9rem;
	color: #4285f4;
	background-color: #ffff;
	width: 90%;
	top: -15px;
	position: relative;
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
	padding: 0px 16px 25px 16px;
	border: 1px solid #d8d8d8;
	box-shadow: 0px 4px 10px rgba(23, 43, 133, 0.15);
}

.plan__ContainerDescription__list {
	margin-top: 20px;
}

.plan__ContainerDescription__item {
	padding-top: 15px;
}

.plan__ContainerOptions__wrapper {
	display: flex;
	flex-direction: row;
	width: 100%;
	z-index: 2;
	position: absolute;
	top: -50px;
}

.plan__ContainerOptions__itemLeft {
	display: flex;
	justify-content: center;
	background-color: rgb(151, 187, 247);
	width: 50%;
	color: #fff;
	border-radius: 8px;
	transition: all ease-in-out 0.3s;
	cursor: pointer;
}

.plan__ContainerOptions__itemLeft:hover,
.plan__ContainerOptions__itemRight:hover {
	scale: 1.1;
	z-index: 3;
	background-color: #4285f4 !important;
}

.plan__ContainerOptions__itemLeft:hover .active {
	opacity: 0.2;
	scale: 0.9 !important;
}

.plan__ContainerOptions__itemRight {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: rgb(151, 187, 247);
	width: 50%;
	color: #fff;
	border-radius: 8px;
	transition: all ease-in-out 0.3s;
	cursor: pointer;
}

.active {
	font-weight: bold;
	background-color: #4285f4;
	scale: 1.1;
	z-index: 2;
}

.plan__ContainerOptions__itemText {
	padding: 20px;
}

.plan__ContainerOptions__promo {
	display: flex;
	justify-content: center;
	font-size: 12px;
	background-color: rgb(156, 204, 38);
	width: 100%;
	color: #fff;
	border-bottom-right-radius: 8px;
	border-bottom-left-radius: 8px;
	padding: 2px;
}

@media (max-width: 950px) {
	.plan__HomePriceContainer {
		width: 100%;
	}
	.plan__MainContainer {
		width: 100%;
	}
	.plan__PricesOption_Container {
		flex-wrap: wrap;
	}
	.plan__Title {
		font-size: 1.6rem;
	}
}
