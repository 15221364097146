.text_title_blue_1 {
	color: #4285f4;
	font-weight: bold;
	font-size: 1.6rem;
}

.text_title_black_sm {
	font-weight: bold;
	font-size: 1.3rem;
	color: black;
}

.text_title_black_md {
	font-weight: bold;
	font-size: 1.6rem;
	color: black;
}

.text_title_black_lg {
	font-weight: bold;
	font-size: 2rem;
	color: black;
}

.text_description_black_1 {
	font-size: 1.1rem;
	color: black;
}
