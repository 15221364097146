.filters_container {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.filters_input_box > div {
	padding: 3px 10px !important;
	border: 1px solid #57585ad2 !important;
	border-radius: 20px !important;
}

.filters_input_box > div > fieldset {
	border: none !important;
}

.filters_input_box > input {
	color: #57585ad2 !important;
	font-size: 1rem !important;
}

.filters_input_box > div > input {
	color: #57585ad2 !important;
	font-size: 1rem !important;
}

.filters_input_box > label[data-shrink='true'] {
	display: none;
}

.filters_input_box > label,
.filters_input_box > div > input {
	font-size: 1rem !important;
	color: #57585ad2 !important;
	font-family: 'Raleway', sans-serif !important;
	top: -4px;
}

.filters_subtitle {
	font-size: 0.9rem;
	font-weight: bold;
	color: #4285f4;
}
.filters_subtitle_wrapper {
	display: flex;
	gap: 10px;
	align-items: center;
	margin-bottom: 0.7rem;
}

.icon_container {
	position: relative;
	display: flex;
}
.question_icon:hover {
	transform: scale(1.1);
}

.question_icon {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 20px;
	height: 20px;
	background-color: #57585ad2;
	border-radius: 50%;
	color: white;
	text-align: center;
	line-height: 20px;
	cursor: pointer;
}

.total_pendings_icon {
	display: flex;
	justify-content: center;
	align-items: flex-start;
	width: 24px;
	height: 24px;
	background-color: white;
	border-radius: 50%;
	color: #4285f4;
	text-align: center;
	line-height: 20px;
	cursor: pointer;
	font-size: 13px;
}

.question_icon::before {
	content: '?';
	font-size: 12px;
}

.tooltip {
	background-color: rgba(0, 0, 0, 0.8);
	color: white;
	border-radius: 4px;
	padding: 8px;
	font-size: 14px;
}

.filters_options_active {
	display: flex;
	font-weight: bold;
	align-items: center;
	background-color: #4285f4;
	color: white;
	gap: 15px;
	padding: 10px 25px;
	border-radius: 20px;
}

.filters_options .total_pendings_icon {
	background-color: #57585ad2;
	color: white;
}

.filters_options {
	display: flex;
	align-items: center;
	color: #57585ad2;
	gap: 15px;
	padding: 10px 25px;
	border-radius: 20px;
	border: 1px solid #57585ad2;
}
