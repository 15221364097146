.detail_wrapper {
	background-color: rgba(239, 239, 239, 0.3);
	padding: 15px;
	border-radius: 20px;
	margin-bottom: 10px;
	display: flex;
	align-items: center;
	justify-content: space-around;
	cursor: pointer;
}

.detail_wrapper:hover {
	background-color: rgba(93, 92, 92, 0.3);
	color: #fff;
}

.detail_wrapper:hover .detail_status {
	color: #fff !important;
	border: 1px solid #fff;
}

.detail_header_wrapper {
	background-color: rgba(93, 92, 92, 0.3);
	color: #000;
	padding: 25px 75px 25px 40px;
	border-radius: 20px;
	margin-bottom: 10px;
	display: flex;
	align-items: center;
}

.detail_element {
	width: 20%;
	text-align: center;
}

.detail_header_element {
	width: 20%;
	font-weight: bold;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 8px;
	padding: 10px;
	border-radius: 15px;
}

.detail_header_element:hover,
.detail_header_element.active {
	background-color: rgba(93, 92, 92, 0.3);
	color: #fff;
}

.detail_header_element_center {
	width: 20%;
	font-weight: bold;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 5px;
}

.detail_element_download {
	width: 20%;
	display: flex;
	justify-content: center;
}

.detail_element_seemore {
	transition: all 0.3s ease-in-out;
}

.detail_element_seemore:hover {
	transform: scale(1.1);
	color: #4285f4;
}

.detail_status {
	border: 1px solid #4285f4;
	padding: 8px 15px;
	border-radius: 12px;
	width: fit-content;
	min-width: 115px;
	max-width: 85%;
	text-align: center;
	color: #4285f4;
}

.detail_status.approved {
	border: 1px solid #9ccc26;
	color: #9ccc26;
}

.detail_status.pending {
	border: 1px solid #db6363;
	color: #db6363;
}
.detail_status.no_status {
	border: 1px solid rgba(93, 92, 92);
	color: rgba(93, 92, 92);
}

.icon_download:hover {
	transform: scale(1.1);
}
