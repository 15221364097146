.insignia_footer {
	display: flex;
	gap: 20px;
	width: 150px;
	margin-top: 20px;
	margin-bottom: 20px;
}

.insignia_footer_img:hover {
	transform: scale(1.05);
	cursor: pointer;
}

.insignia_footer_disabled {
	opacity: 0.5;
}
