.modal_overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	min-width: 1000px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(87, 87, 87, 0.55);
	z-index: 1000;
}
.modal_container {
	background-color: #ffff;
	box-shadow: 0px 10px 10px rgba(23, 43, 133, 0.15);
	border-radius: 15px;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	flex-direction: column;
	padding: 20px;
	max-height: 90vh;
}

.modal_title {
	font-size: 32px;
	font-weight: bold;
}

.modal_subtitle {
	font-size: 16px;
	font-weight: bold;
	color: rgba(87, 87, 87, 0.55);
}

.modal_button {
	width: auto;
	min-width: 290px;
	font-weight: 500;
	font-size: 24px;
	padding-top: 1rem;
	padding-bottom: 1rem;
	padding-left: 1.25rem;
	padding-right: 1.25rem;
	background-color: #4285f4;
	color: #ffff;
	border-radius: 1rem;
}

.modal_button.white {
	background-color: #fff;
	border: 2px solid #4285f4;
	color: #4285f4;
	border-radius: 1rem;
	min-width: 290px;
}

.modal_wrapper_button {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 20px;
	flex-direction: column;
}

.modal_error_wrapper {
	display: flex;
	justify-content: center;
	position: absolute;
	top: 50px;
	margin-left: 250px;
	transition: all 0.3s ease-in-out;
}

.modal_error {
	font-size: 1.2rem;
	color: #fff;
	padding: 20px;
	background-color: #db6363;
	margin-bottom: 1.2rem;
	border-radius: 1.5rem;
}

.modal_error_message {
	font-size: 1.2rem;
	padding: 20px;
	color: #db6363;
	margin-bottom: 1.2rem;
	border-radius: 1.5rem;
}

.modal_form {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.modal_form_wrapper_column {
	display: flex;
	justify-content: center;
	align-items: flex-start;
	flex-direction: row;
	max-height: 45vh;
	overflow: auto;
	margin-top: 1rem;
	margin-bottom: 1rem;
}

.modal_form_column {
	display: flex;
	justify-content: center;
	align-items: flex-start;
	flex-direction: column;
	padding: 20px 30px 40px;
}

.modal_info_container {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: flex-start;
	min-width: 700px;
	padding: 0 20px;
	margin-bottom: 2rem;
	margin-top: 2rem;
	max-height: 70vh;
	overflow: auto;
}

.modal_info_container::-webkit-scrollbar-track,
.modal_form_wrapper_column::-webkit-scrollbar-track {
	background-color: #ffff;
}

.modal_info_container::-webkit-scrollbar,
.modal_form_wrapper_column::-webkit-scrollbar {
	width: 11px;
	background-color: #ffff;
}

.modal_info_container::-webkit-scrollbar-thumb,
.modal_form_wrapper_column::-webkit-scrollbar-thumb {
	background-color: #57585ad2;
	border-radius: 10px;
}

.modal_info_element {
	width: 100%;
	display: flex;
	align-items: flex-start;
	margin-bottom: 1rem;
	flex-direction: column;
	text-align: left;
}

.modal_info_element_text {
	width: 100%;
	display: flex;
	align-items: center;
	gap: 1.2rem;
	cursor: pointer;
	padding: 8px 10px;
	border-radius: 15px;
	position: relative;
}

.modal_info_element_error {
	color: #db6363;
}

.modal_info_user {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 1.2rem;
	cursor: pointer;
	padding: 18px 40px;
	border-radius: 15px;
	position: relative;
	background-color: rgba(93, 92, 92, 0.1);
	margin-bottom: 0.5rem;
	min-height: 70px;
}

.modal_info_user_nohover {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 1.2rem;
	padding: 18px 40px;
	border-radius: 15px;
	position: relative;
	background-color: rgba(93, 92, 92, 0.1);
	margin-bottom: 0.5rem;
	min-height: 70px;
}

.modal_info_nousers {
	height: 200px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #4285f4;
	font-weight: 600;
	font-size: 1.2rem;
}

.modal_info_element_title {
	color: #4285f4;
}

.modal_info_element_subtitle {
	text-align: right;
}

.modal_info_element_status {
	background-color: #98cf22e0;
	height: 7px;
	width: 7px;
	border-radius: 50%;
}

.modal_info_element_status.modal_info_element_pending {
	background-color: #db6363;
}

.modal_info_user:hover {
	background-color: #4285f4;
}
.modal_info_user:hover .modal_info_element_title,
.modal_info_user:hover .modal_info_element_subtitle,
.modal_info_user:hover .modal_info_element_error {
	color: #fff !important;
}
.modal_info_user:hover .modal_info_element_status {
	background-color: #fff !important;
}

.modal_info_img_wrapper {
	min-width: 700px;
	max-height: 40vh;
	min-height: 200px;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 1rem;
}

.modal_info_img_wrapper img {
	max-width: 100%;
	max-height: 100%;
	width: auto;
	height: auto;
}

.modal_info_payment_wrapper {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1rem 4rem;
}

.modal_info_payment_wrapper h2 {
	color: #4285f4;
	font-size: 0.9rem;
}

.icon_close_wrapper {
	position: absolute;
	top: 25px;
	right: 25px;
	height: auto;
	cursor: pointer;
}

.icon_close_wrapper:hover {
	transform: scale(1.1);
	opacity: 0.8;
}

.modal_fileUploader_wrapper {
	padding: 0 40px;
	width: 100%;
}

.modal_success_title {
	font-size: 30px;
	color: #4285f4;
	font-weight: bold;
}

.modal_success_message {
	font-size: 24px;
	color: #b9b9b9;
}

.modal_info_arrow {
	opacity: 0;
}

.modal_info_user:hover .modal_info_arrow {
	animation: arrowAnimation 1s forwards;
}

.filters_input_box {
	padding: 3px 10px !important;
	border: 1px solid #57585ad2 !important;
	border-radius: 20px !important;
}

.filters_input_box > fieldset {
	border: none !important;
}

.filters_input_box > input {
	color: #57585ad2 !important;
	font-size: 1rem !important;
}

.filters_input_box > div > input {
	color: #57585ad2 !important;
	font-size: 1rem !important;
}

.filters_input_box > label[data-shrink='true'] {
	display: none;
}

.filters_input_box > label,
.filters_input_box > div > input {
	font-size: 1rem !important;
	color: #57585ad2 !important;
	font-family: 'Raleway', sans-serif !important;
	top: -4px;
}

.filters_subtitle {
	font-size: 0.9rem;
	font-weight: bold;
	color: #4285f4;
}

.modal_checkbox_wrapper {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 1.2rem;
	margin-left: 60px;
	margin-top: 1rem;
}

.modal_info_close_wrapper {
	position: absolute;
	right: 10px;
	color: #4285f4;
	transition: all 0.3s;
}
.modal_info_user:hover .modal_info_close_wrapper {
	color: #fff !important;
}

.modal_info_close_wrapper:hover {
	scale: 1.2;
}

.modal_info_loading_container {
	min-height: 200px;
	min-width: 700px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.modal_info_warning_payment {
	color: #fff;
	font-size: 0.9rem;
	background-color: #4285f4;
	padding: 5px 20px;
	border-radius: 8px;
	transition: all 0.3s;
}

.modal_info_user:hover .modal_info_warning_payment {
	background-color: #fff;
	color: #4285f4;
}

.modal_info_warning_payment:hover {
	scale: 1.1;
}

@keyframes arrowAnimation {
	0% {
		opacity: 0;
		transform: translateX(0);
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 1;
		transform: translateX(20px);
	}
}
