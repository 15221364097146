.loading_container {
	width: 100%;
	height: 40vh;
	display: flex;
	justify-content: center;
	align-items: center;
}

.table_container {
	background-color: rgba(239, 239, 239, 0.3);
	border-radius: 25px;
	box-shadow: 0px 4px 4px rgba(23, 43, 133, 0.15);
	padding: 60px 20px;
	min-height: 400px;
}

.table_title {
	font-size: 1.3rem;
	font-weight: bold;
	color: black;
	margin-bottom: 0.7rem;
	text-align: center;
}

.table_subtitle {
	max-width: 500px;
}

.table_subtitle_wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 220px;
	padding: 0 90px;
	text-align: center;
}
