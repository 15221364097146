.smooth_transition {
	transition: all 0.3s ease-in-out;
}

.cursor_pointer {
	cursor: pointer;
}

.cursor_pointer:hover {
	transform: scale(1.05);
}

.error_Notification {
	text-align: center;
	color: rgb(219, 99, 99);
	position: absolute;
	padding: 10px 20px;
	border: rgb(219, 99, 99) solid 2px;
	border-radius: 12px;
}

.success_Notification {
	text-align: center;
	color: #4285f4;
	position: absolute;
	padding: 10px 20px;
	border: #4285f4 solid 2px;
	border-radius: 12px;
}

.uploadFile_container {
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;
}

.uploadFile_modal {
	min-height: 300px;
	width: 650px;
	flex-wrap: wrap;
	padding: 15px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #ffff;
	border-radius: 2rem;
	box-shadow: 0 4px 7px rgba(0, 0, 0, 0.2);
}

.uploadFile_header {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 10px;
	font-size: 30px;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
}

.uploadFile_body {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.uploadFile_dropZone {
	width: 100%;
}

.uploadFile_buttonWrapper {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 15px;
}

.input_editable {
	margin-top: 0.7rem;
	margin-left: 0.5rem;
	cursor: pointer;
	background-color: rgba(239, 239, 239, 0.4);
	border-radius: 15px;
	padding: 10px;
	color: black;
}

.inputFile_editable {
	margin-top: 0.7rem;
	margin-left: 0.5rem;
	cursor: pointer;
	background-color: rgba(239, 239, 239, 0.4);
	border-radius: 15px;
	padding: 10px;
	color: black;
}

.input_editable_empty {
	text-align: center;
	margin-top: 0.7rem;
	margin-left: 0.5rem;
	cursor: pointer;
	border-radius: 15px;
	padding: 10px;
	border-color: rgb(219, 99, 99);
	color: rgb(219, 99, 99);
	font-size: 1rem;
	background-color: rgba(219, 99, 99, 0.2);
}

.inputFile_editable_empty {
	text-align: center;
	margin-top: 0.7rem;
	margin-left: 0.5rem;
	cursor: pointer;
	border-radius: 15px;
	padding: 10px;
	border-color: rgb(219, 99, 99);
	color: rgb(219, 99, 99);
	font-size: 1rem;
	background-color: rgba(219, 99, 99, 0.2);
}

.input_editable_empty:focus {
	border: 2px solid #4285f4;
	background-color: #ffff;
	color: #4285f4;
}

.input_editable:hover,
.inputFile_editable:hover {
	border: 2px solid #4285f4;
	background-color: #ffff;
	color: #4285f4;
}

.inputFile_editable_empty:hover,
.input_editable_empty:hover {
	transform: scale(1.1);
	outline-color: rgb(219, 99, 99);
}

.inputFile_editable_empty:focus {
	outline-color: rgb(219, 99, 99);
}

.input_error {
	margin-left: 0.5rem;
	position: absolute;
	font-size: 1rem;
	color: rgb(219, 99, 99);
}

.input_success {
	position: absolute;
	font-size: 1rem;
	color: #4285f4;
	margin-left: 0.5rem;
}

input {
	outline-color: #4285f4;
}

.input_editable:focus-visible,
.input_editable:focus,
.input_editable_empty:focus {
	background-color: #ffff;
	color: #4285f4;
}

.icon_container {
	position: relative;
	display: flex;
}
.question_icon:hover {
	transform: scale(1.1);
}

.question_icon {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 16px;
	height: 16px;
	background-color: #57585ad2;
	border-radius: 50%;
	color: white;
	text-align: center;
	line-height: 16px;
	cursor: pointer;
}

.question_icon::before {
	content: '?';
	font-size: 12px;
}

.tooltip {
	background-color: rgba(0, 0, 0, 0.8);
	color: white;
	border-radius: 4px;
	padding: 8px;
	font-size: 14px;
}

.uploadFile_modalComplete {
	position: relative;
	min-height: 300px;
	width: 650px;
	flex-wrap: wrap;
	flex-direction: column;
	padding: 15px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #ffff;
	border-radius: 2rem;
	box-shadow: 0 4px 7px rgba(0, 0, 0, 0.2);
}

.uploadFile_headerComplete {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 30px;
	margin-bottom: 20px;
}

.uploadFile_bodyHeaderComplete {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 25px;
}

.uploadFile_bodyTextComplete {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-left: 25px;
	padding-right: 25px;
	text-align: center;
}
